
import $ from 'jquery';
import 'slick-carousel';

$(function(){ 

    (function banner(){
        
        $(".js-home-banner").slick({
            dots: false, 
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
        });

    })();

    (function quem_somos(){

        const element = '.js-home-quem-somos';
 
        $(element).slick({
            dots: false, 
            arrows: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1, 
            variableWidth: true,
            // adaptiveHeight: false,
            prevArrow: $(".js-arrow-prev"),
            nextArrow: $(".js-arrow-next"),
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {  
                        variableWidth: false,
                        slidesToShow: 1, 
                        slidesToScroll: 1, 
                    }
                }
            ]  
        });
  
        function updateSlideInfo(slick, currentSlide) {
            var slideFactor = $(window).width() >= 1200 ? 1 : 1; 
            var totalSlides = Math.ceil($(element).find('.slick-slide').length / slideFactor);
            currentSlide = Math.ceil((currentSlide + 1) / slideFactor);
            $(".js-lang").text(lang === 'pt-br' ? 'DE' : 'OF');
            $(".js-current").text(currentSlide < 10 ? '0' + currentSlide : currentSlide);
            $(".js-last").text(totalSlides < 10 ? '0' + totalSlides : totalSlides);
        }
        
        var checkSlickInit = setInterval(function() {
            if ($(element).hasClass('slick-initialized')) {
                var slick = $(element).slick('getSlick');
                updateSlideInfo(slick, 0);
                clearInterval(checkSlickInit);
            }
        }, 100);
        
        $(element).on('afterChange', function(event, slick, currentSlide) {
            updateSlideInfo(slick, currentSlide);
        });
        
        
    })();

    (function nossos_numeros() {
        
    })();
    
 
    (function sustentabilidade(){
        /** Scripts da seção sustentabilidade **/
    })();

    (function central_de_resultados(){
        /** Scripts da seção central_de_resultados **/
    })();

    (function carreiras(){
        /** Scripts da seção carreiras **/
    })();
});
